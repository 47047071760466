export const calculatePrecision = function (val) {
  const value = Number(val);
  if (value >= 1000) return 2;
  else if (value >= 10 && value < 1000) return 4;
  else return 8;
};

export const formatNumber = (number, decimals) => {
  return Number(number).toFixed(decimals);
};

export const formatAmount = (val) => {
  return formatNumber(val, calculatePrecision(val));
};
